import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, FormControl, Select, IconButton, CircularProgress } from '@material-ui/core'
import { useFormInput } from '../CustomHooks';
import SubmissionTable from './SubmissionTable';
import EventStaffingTable from './EventStaffingTable';
import EventStaffingDemandTable from './EventStaffingDemandTable';
import EventAvailableBedsTable from './EventAvailableBedsTable';
import EventTechnologyStatusTable from './EventTechnologyStatusTable';
import EventResourceStatusTable from './EventResourceStatusTable';
import EventTRAINTable from './EventTRAINTable';
import StaffingPieChart from './StaffingPieChart';
import StaffingDemandPieChart from './StaffingDemandPieChart';
import PatientStatusPieChart from './PatientStatusPieChart';
import BedStatusPieChart from './BedStatusPieChart';
import TechnologyBarChart from './TechnologyBarChart';
import ResourceBarChart from './ResourceBarChart';
import EventNameInput from './EventNameInput';
import ConfirmCloseEventDialog from './ConfirmCloseEventDialog';
import TRAINBarChart from './TRAINBarChart';
import EventImpactTable from './EventImpactTable';
import EventProblemsTable from './EventProblemsTable';
import EventUrgentNeedTable from './EventUrgentNeedTable';
import EventTableAggregatedPage from './EventTableAggregatedPage';
import ChangeEventButton from './ChangeEventButton';
import { Route, Switch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { getAPIUrl, handleAxiosError } from '../AppUtils';
import { format } from 'date-fns';


const styles = theme => ({
    titleBoldText:{
        fontSize: 18,
        fontWeight: "bold",
    },
    titleNormalText:{
        fontSize: 18,
        fontWeight: "normal",
    },
    nameBox:{
        marginRight: 50,
    },
    statusBox:{
        marginRight: 10,
    },
    formControl: {
        width: 320,
    },
    select: {
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2",
        backgroundColor: "#FFF",
        color: "#555555",
        fontSize: 14,
    },
    selectSelect: {
        height: 20,
    },
    spacing: {
        height: 30,
    },
    input: {
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2",
        backgroundColor: "#FFF",
        color: "#555555",
        fontSize: 14,
    },
    closeEventButton: {
        color: theme.palette.text.primary,
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

function EventDetails(props) {
    const { classes, eventId, eventDetails, filters, eventStaffing, eventStaffingDemand, eventBedAvailability, 
        eventTechnologyStatus, eventResourceStatus, eventSubmissionStatus, singleHospital, userHospital,
    eventTRAIN, eventImpact, eventProblems, eventUrgentNeed, departments, refreshEvent, openEvents, orgSettings } = props;
    const filterKey = filters.length>0?filters[0].info.key:undefined;
    const filterOptions = filters.length>0?filters[0].options:undefined;
    const filter = useFormInput("");
    const [eventNameEditable, setEventNameEditable] = useState(false);
    const [closeEventDialogOpen, setCloseEventDialogOpen] = useState(false);
    const [filterChoice, setFilterChoice] = useState({});
    const [downloading, setDownloading] = useState(false);

    const colors = ["#0087BF","#006691","#054660","#223749","#91dfff","#66d3ff","#3ac7ff","#00b7ff"];
    // const colors = ["#223749","#3ac7ff"]
    var departmentStatus = eventSubmissionStatus.departments;
    var totalStaffing = eventStaffing.staffing;
    let totalStaffingDemand = [];
    let staffingDemandTemp = 0;
    let staffingDemandPerm = 0;
    if (orgSettings.staffingDemand) {
        totalStaffingDemand = eventStaffingDemand.staffingDemand;
        let filterTemp = totalStaffingDemand.filter(n => n["status"] === "perm")
        staffingDemandPerm = filterTemp.length;
        staffingDemandTemp = totalStaffingDemand.length - staffingDemandPerm;
    }
    
    var bedAvailability = eventBedAvailability.availability;
    var technologyItemStatus = eventTechnologyStatus.status;
    var resourceItemStatus = eventResourceStatus.status;
    var TRAINData = eventTRAIN?eventTRAIN.data:undefined;
    var impact = eventImpact.departments;
    var problems = eventProblems.departments;
    var urgentNeed = eventUrgentNeed.departments;
    var totalPatientCount = 0;
    var filterByValue = undefined;
    var filterByKey = filterKey;
    var additionalFilters = filters.slice(1);
    function handleFilterChange(index, key, e){
        let newFilterChoice = {
            ...filterChoice,
            [key]: e.target.value
        }
        // filters.slice(index+1).forEach((n,i)=>{
        //     newFilterChoice[n.info.key]="";
        // });
        setFilterChoice(newFilterChoice);
    }

    function downloadEventPDF(){
        const eventUrl = eventId?`/eventPDF/${eventId}`:'/eventPDF';
        setDownloading(true);
        axios.post(getAPIUrl()+eventUrl,{
            filters: {[filterKey]: filter.value, ...filterChoice},
            userCurrentTime: format(new Date(),'MMM dd, yyyy hh:mm a')
        })
        .then(response=>{
            setDownloading(false);
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'unknown.pdf';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename=(.+)/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            var blob = new Blob([response.data]);
            if (navigator.appVersion.toString().indexOf('.NET') > 0)
                window.navigator.msSaveBlob(blob, fileName);
            else
            {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(error=>{
            setDownloading(false);
            handleAxiosError(error)
        })
    }
    
    if(singleHospital){
        filterByKey = 'hospital';
        if(!userHospital.id){
            departmentStatus= [];
            totalStaffing = [];
            totalStaffingDemand = [];
            bedAvailability = [];
            technologyItemStatus = [];
            resourceItemStatus = [];
            TRAINData = undefined;
        }
        else{
            filterByValue = userHospital.id;
        }
    }
    else{
        filterByValue = filter.value!==""?parseInt(filter.value):undefined
    }
    const aggregatesUsed = [filterByKey]
    const filtersUsed = []
    if(filterByValue){
        filtersUsed.push(filterByValue)
        departmentStatus = departmentStatus.filter(n=>n[filterByKey]===filterByValue);
        totalStaffing = totalStaffing.filter(n=>n[filterByKey]===filterByValue);
        totalStaffingDemand = totalStaffingDemand.filter(n=>n[filterByKey]===filterByValue);
        bedAvailability = bedAvailability.filter(n=>n[filterByKey]===filterByValue)
        technologyItemStatus = technologyItemStatus.filter(n=>n[filterByKey]===filterByValue);
        resourceItemStatus = resourceItemStatus.filter(n=>n[filterByKey]===filterByValue);
        impact = impact.filter(n=>n[filterByKey]===filterByValue);
        problems = problems.filter(n=>n[filterByKey]===filterByValue);
        urgentNeed = urgentNeed.filter(n=>n[filterByKey]===filterByValue);
        if(TRAINData){
            TRAINData = TRAINData.filter(n=>n[filterByKey]===filterByValue);
        }
        //this is not very good/incorrect, change later
        if(additionalFilters.length > 0){
            var i = 0;
            for(i = 0; i < additionalFilters.length; i++){
                let key = additionalFilters[i].info.key;
                let val = filterChoice[key]?parseInt(filterChoice[key]):undefined
                let possibleOptions = departments.filter(n=>
                    // relevantFilters.every(filter=>parseInt(n[filter.info.key])===parseInt(newFilterChoice[filter.info.key]))
                    parseInt(n[filterByKey])===parseInt(filterByValue)
                ).reduce((obj,n)=>{
                    obj[n[key]] = true;
                    return obj
                },{});
                additionalFilters[i] = Object.assign({},additionalFilters[i])
                additionalFilters[i].options = filters[i+1].options.filter(n=>possibleOptions[n.id])
                if(val){
                    if(additionalFilters[i].options.some(n=>n.id===val)){
                        aggregatesUsed.push(key);
                        filtersUsed.push(val);
                        departmentStatus = departmentStatus.filter(n=>n[key]===val);
                        totalStaffing = totalStaffing.filter(n=>n[key]===val);
                        totalStaffingDemand = totalStaffingDemand.filter(n=>n[key]===val);
                        bedAvailability = bedAvailability.filter(n=>n[key]===val)
                        technologyItemStatus = technologyItemStatus.filter(n=>n[key]===val);
                        resourceItemStatus = resourceItemStatus.filter(n=>n[key]===val);
                        impact = impact.filter(n=>n[key]===val);
                        problems = problems.filter(n=>n[key]===val);
                        urgentNeed = urgentNeed.filter(n=>n[key]===val);
                        if(TRAINData){
                            TRAINData = TRAINData.filter(n=>n[key]===val);
                        }
                    }
                    else{
                        console.log(additionalFilters[i].options)
                        // filterChoice[key] = "";
                    }
                }
            }
        }
    }
    else{
        additionalFilters = [];
    }
    
    const totalAvailable = totalStaffing.reduce((sum,n)=>sum+n.available,0)
    const totalOccupied = totalStaffing.reduce((sum,n)=>sum+n.present,0)-totalAvailable
    var bedDict = eventBedAvailability.types.reduce((obj,type)=>{
        obj[type.id]=eventBedAvailability.statuses.reduce((obj2,status)=>{
                obj2[status.id]=status.categories.reduce((obj3,category)=>{
                    obj3[category.id] = 0;
                    return obj3;
                },{})
                return obj2;
            },{})
        return obj;
    },{})
    bedAvailability = bedAvailability.reduce((obj,entry)=>{
        obj[entry.type][entry.status][entry.category]=obj[entry.type][entry.status][entry.category]+entry.number
        if(entry.status===1){
            //patient present
            totalPatientCount += entry.number;
        }
        return obj;
    },bedDict)
    if(eventTRAIN){
        var TRAINDict = eventTRAIN.types.reduce((obj,type)=>{
            obj[type.id]=eventTRAIN.categories.reduce((obj2,category)=>{
                obj2[category.id] = 0;
                    return obj2;
                },{})
            return obj;
        },{})
        TRAINData = TRAINData.reduce((obj,entry)=>{
            obj[entry.type][entry.category]=obj[entry.type][entry.category]+entry.number
            return obj;
        },TRAINDict)
    }
    var technologyItems = eventTechnologyStatus.items;
    technologyItems.forEach(item=>{
        const itemStatus = technologyItemStatus.filter(n=>n.technologyItemId===item.id);
        eventTechnologyStatus.technologyStatuses.forEach(status=>{
            item[status.id] = 0;
        });
        itemStatus.forEach(entry=>{
            item[entry.status] += 1;
        })
    })
    var resourceItems = eventResourceStatus.items;
    resourceItems.forEach(item=>{
        const itemStatus = resourceItemStatus.filter(n=>n.resourceId===item.id);
        item.onHand = itemStatus.reduce((sum,entry)=>sum+entry.onHand,0)
        item.availableForDeployment = itemStatus.reduce((sum,entry)=>sum+entry.availableForDeployment,0)
    })
    const aggregateString = aggregatesUsed.join('&');
    const filterString = filtersUsed.join('&');
    return (
        <div>
            {/* <Switch> */}
        <Route exact path='/event/:eventId?/:table/:tableId(\d+)/aggregate/:aggregates'
            render={(props) => 
                <EventTableAggregatedPage {...props} 
                    history={props.history}
                    />
            } 
        />
        <Route exact path='/event/:eventId?/:table/:tableId(\d+)/aggregate/:aggregates/filter/:filters'
            render={(props) => 
                <EventTableAggregatedPage {...props} 
                    history={props.history}
                />
            } 
        />
        <Route exact path='/event/:eventId?' render={(props) => 
            <Grid container>
            <Grid item container xs={12} style={{marginBottom:10}}>
                <Grid item xs={6}>
                    <div className={classes.nameBox}>
                        <Grid container alignItems="center">
                            <Typography className={classes.titleBoldText}>Event:&nbsp;</Typography>
                            {
                                orgSettings.noEventCreation?
                                    eventNameEditable?
                                        <EventNameInput
                                            initialName={eventDetails.name}
                                            setEventNameEditable={setEventNameEditable}
                                            eventId={eventDetails.id}
                                            refreshEvent={refreshEvent}
                                        />
                                        :<Typography className={classes.titleNormalText} display="inline"
                                            onClick={()=>setEventNameEditable(true)} 
                                        >
                                            {eventDetails.name}
                                        </Typography>
                                    :
                                    eventDetails.status?<ChangeEventButton style={{marginLeft:10}} openEvents={openEvents} history={props.history}/>:
                                    <Typography className={classes.titleNormalText} display="inline"> 
                                        {eventDetails.name}
                                    </Typography>
                            }
                            {downloading?
                                <CircularProgress size={30} color="secondary" style={{marginLeft: 5}}/>:
                                <IconButton className={classes.iconButton}
                                    onClick={downloadEventPDF}
                                >
                                    <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/>
                                </IconButton>
                            }
                        </Grid>
                    </div>

                </Grid>
                <Grid item xs={6} container alignItems="center" justify='flex-end'>
                    <div className={classes.statusBox}>
                        <Typography className={classes.titleBoldText} display="inline">Status:&nbsp;</Typography>
                        <Typography className={classes.titleNormalText} display="inline">{eventDetails.status?"Open":"Closed"} </Typography>
                    </div>
                    {eventDetails.status?
                        <Button className={classes.closeEventButton} 
                            variant="contained" color="secondary" size="small"
                            onClick={()=>setCloseEventDialogOpen(true)} 
                        >
                            Close Event
                        </Button>:undefined
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                {filterByKey?
                    singleHospital?
                    <Typography className={classes.titleBoldText}>{userHospital.name}</Typography>:
                    <Grid>
                        <FormControl className={classes.formControl}>
                            <Select
                                className={classes.select}
                                disableUnderline 
                                native
                                value={filter.value}
                                onChange = {(e)=>{
                                    filter.onChange(e);
                                    setFilterChoice({});
                                }}
                                classes={{select:classes.selectSelect}}
                            >
                                <option className={classes.option} value="">
                                    &nbsp;&nbsp;&nbsp;&nbsp;All Summary
                                </option>
                                {filterOptions.map(filter =>
                                    <option key={filter.id} className={classes.option} value={filter.id}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{filter.name}
                                    </option>)
                                }
                            </Select>
                        </FormControl>
                        {additionalFilters.map((n,i)=>
                            <FormControl key={n.info.key} className={classes.formControl}>
                                <Select
                                    className={classes.select}
                                    disableUnderline 
                                    native
                                    onChange={e=>handleFilterChange(i,n.info.key,e)}
                                    value={filterChoice[n.info.key]}
                                    classes={{select:classes.selectSelect}}
                                >
                                    <option className={classes.option} value="">
                                        &nbsp;&nbsp;&nbsp;&nbsp;All
                                    </option>
                                    {n.options.map(filter =>
                                        <option key={filter.id} className={classes.option} value={filter.id}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;{filter.name}
                                        </option>)
                                    }
                                </Select>
                            </FormControl>
                        )}

                    </Grid>
                :undefined
                }
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            {urgentNeed.length>0?
                <React.Fragment>
                    <Grid item xs={12}>
                        <EventUrgentNeedTable
                            departments={urgentNeed}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.spacing}/>
                </React.Fragment>:undefined
            }
            <Grid item xs={12}>
                <SubmissionTable
                    departments={departmentStatus}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            <Grid item xs={12}>
                <EventStaffingTable
                    eventId={eventId}
                    allStaffTitles={eventStaffing.allTitles}
                    totalStaffing={totalStaffing}
                    filterValue={filterString}
                    aggregates={aggregateString}
                    additionalColumns={eventStaffing.additionalColumns}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            {orgSettings.staffingDemand &&
                <Grid item xs={12}>
                    <EventStaffingDemandTable
                        eventId={eventId}
                        allStaffTitles={eventStaffingDemand.allTitles}
                        totalStaffingDemand={totalStaffingDemand}
                        filterValue={filterString}
                        aggregates={aggregateString}
                    />
                </Grid>
            }
            <Grid item xs={12} className={classes.spacing}/>
            <Grid item xs={12}>
                <EventAvailableBedsTable
                    eventId={eventId}
                    bedTypes={eventBedAvailability.types}
                    bedStatuses={eventBedAvailability.statuses}
                    bedAvailability={bedAvailability}
                    filterValue={filterString}
                    totalPatientCount={totalPatientCount}
                    aggregates={aggregateString}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            {TRAINData?
                <React.Fragment>
                    <Grid item xs={12}>
                        <EventTRAINTable
                            eventId={eventId}
                            TRAINTypes={eventTRAIN.types}
                            TRAINCategories={eventTRAIN.categories}
                            TRAINData={TRAINData}
                            filterValue={filterString}
                            aggregates={aggregateString}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.spacing}/>
                </React.Fragment>:undefined
            }
            <Grid item xs={12}>
                <EventTechnologyStatusTable
                    eventId={eventId}
                    technologyItems={technologyItems}
                    technologyStatuses={eventTechnologyStatus.technologyStatuses.filter(n=>n.name!="N/A")}
                    filterValue={filterString}
                    aggregates={aggregateString}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            <Grid item xs={12}>
                <EventResourceStatusTable
                    eventId={eventId}
                    resourceItems={resourceItems}
                    filterValue={filterString}
                    aggregates={aggregateString}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            {impact.length>0?
                <React.Fragment>
                    <Grid item xs={12}>
                        <EventImpactTable
                            departments={impact}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.spacing}/>
                </React.Fragment>:undefined
            }
            {problems.length>0?
                <Grid item xs={12}>
                    <EventProblemsTable
                        departments={problems}
                    />
                </Grid>:undefined
            }
            <Grid item md={6} className={classes.center}>
                <StaffingPieChart
                    title={"Staffing"}
                    labels={['Occupied Labor Pool'].concat(eventStaffing.additionalColumns.map(n=>n.name)).concat(['Available Labor Pool'])}
                    data={[totalOccupied].concat(eventStaffing.additionalColumns.map(col=>totalStaffing.reduce((sum,n)=>n[col.id]?sum+n[col.id]:sum,0)))
                        .concat([totalAvailable])}
                    colors={colors}
                />
            </Grid>
            {orgSettings.staffingDemand &&
                <Grid item md={6} className={classes.center}>
                    <StaffingDemandPieChart
                        title={"Staffing Demand"}
                        labels={['Permanent', 'Temporary']}
                        data={[staffingDemandPerm, staffingDemandTemp]}
                        colors={colors}
                    />
                </Grid>
            }
            <Grid item md={6} className={classes.center}>
                <PatientStatusPieChart
                    bedTypes={eventBedAvailability.types}
                    bedStatuses={eventBedAvailability.statuses}
                    bedAvailability={bedAvailability}
                    colors={colors}
                />
            </Grid>
           
            <Grid item md={6} className={classes.center}>
                <BedStatusPieChart
                    bedTypes={eventBedAvailability.types}
                    bedStatuses={eventBedAvailability.statuses}
                    bedAvailability={bedAvailability}
                    colors={colors}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            <Grid item md={6}>
                <ResourceBarChart
                    resourceItems={resourceItems}
                    colors={[colors[3],colors[6]]}
                />
            </Grid>
            <Grid item md={6}>
                <TechnologyBarChart
                    technologyItems={technologyItems}
                    technologyStatuses={eventTechnologyStatus.technologyStatuses.filter(n=>n.name!="N/A")}
                    colors={[colors[3],colors[6]]}
                />
            </Grid>
            <Grid item xs={12} className={classes.spacing}/>
            {TRAINData?
                <React.Fragment>
                    <Grid item xs={12}>
                        <TRAINBarChart
                            eventId={eventId}
                            TRAINTypes={eventTRAIN.types}
                            TRAINCategories={eventTRAIN.categories}
                            TRAINData={TRAINData}
                        />
                    </Grid>
                <Grid item xs={12} className={classes.spacing}/>
                </React.Fragment>:undefined
            }
            <ConfirmCloseEventDialog
                open={closeEventDialogOpen}
                handleClose={()=>setCloseEventDialogOpen(false)}
                eventId={eventDetails.id}
                refreshEvent={refreshEvent}
            />
            </Grid>
            }/>
         {/* </Switch> */}
        </div>
    );
}

export default withStyles(styles)(EventDetails);