import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Link, IconButton, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    bottomBar:{
        backgroundColor: theme.palette.primary.main,
    },
    textGrid: {
        paddingLeft: 50,
        paddingTop: 30,
        marginBottom: 20,
    },
    title: {
        fontSize: 18,
    },
    horizLine: {
        border: "none",
        height: 1,
        backgroundColor: "#FFF"
    },
    copyrightText: {
        padding: 20,
        paddingLeft: 40,
    },
    whiteText: {
        color: "#FFF"
    },
});

function BottomBar(props) {
    const { classes } = props;
    return (
        <div className={classes.bottomBar}>
            <Grid container className={classes.textGrid}>
                <Grid item md={3}>
                    <Typography className={classes.title} color="secondary" variant="body1">
                        ABOUT IHS
                    </Typography>
                    <Typography className={classes.whiteText}>
                        Intelligent Health System (IHS) provides hospital administration teams the ability to achieve operational excellence by streamlining workflows.
                    </Typography>
                </Grid>
                <Grid item md={1}/>
                <Grid item md={3}>
                    <Typography className={classes.title} color="secondary" variant="body1">
                        GET IN TOUCH
                    </Typography>
                    <Typography className={classes.whiteText}>
                        SEQ Technology LLC
                    </Typography>
                    <Typography className={classes.whiteText}>
                        1460 Broadway
                    </Typography>
                    <Typography className={classes.whiteText}>
                        New York, NY 10036
                    </Typography>
                    <Typography className={classes.whiteText}>
                        Email: questions@seq-technology.com
                    </Typography>
                    <Typography className={classes.whiteText}>
                        Phone: (646) 225-4039
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography className={classes.title} color="secondary" variant="body1">
                        USEFUL LINKS
                    </Typography>
                    <Typography className={classes.whiteText}>
                        <IconButton
                            fontSize="15"
                            href={"https://www.linkedin.com/company/seq-technology-llc/"}
                        >
                            <FontAwesomeIcon size="xs" icon={["fab","linkedin"]} color="white"/>
                        </IconButton>    
                    </Typography>
                    {/* <Typography className={classes.whiteText}>
                        <Link component={RouterLink} 
                            color="inherit"
                            to="/privacy_policy"
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                    <Typography className={classes.whiteText}>
                        <Link component={RouterLink} 
                            color="inherit"
                            to="/termsandconditions"
                        >
                            Terms and Conditions
                        </Link>
                    </Typography> */}
                </Grid>
            </Grid>
            <hr className={classes.horizLine}/>
            <Typography className={classes.copyrightText} color="secondary" variant="caption">
                Copyright {new Date().getFullYear()} SEQ Technology LLC. All Rights Reserved
            </Typography>
        </div>
    );
}
export default withStyles(styles)(BottomBar);
